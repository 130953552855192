import { IDrawingUpload, IUpdateDrawingInfo } from "./drawing-uploads.types";

export const convertToUpdatableFormat = (
  drawing_info: IDrawingUpload[],
): IUpdateDrawingInfo => {
  const newData = drawing_info.map((drawing, idx) => {
    return {
      sod_no: drawing.sod_no,
      drawingFile: drawing.drawingFile,
      DRGno: drawing.DRGno[0],
      dr_no_accuracy: drawing.DRGno[1],
      Description: drawing.Description[0],
      description_accuracy: drawing.Description[1],

      is_changed: false,
      old_DRGno: drawing.DRGno[0],
      old_Description: drawing.Description[0],
      ocr_log_uuid: drawing.ocr_log_uuid,
      product_code: "",
      accuracy_product_code: 0,
    };
  });

  return {
    sod_no: drawing_info[0].sod_no,
    drawing_items: newData,
  };
};

import { LoadState } from "../../constants/enums";
import {
  IMaterialRequest,
  IMaterialRequestState,
} from "./material-request.types";

export const defaultMaterialRequest: IMaterialRequest = {
  mr_uuid: "",
  order_no: "",
  region: "",
  series: null,
  batch_no: null,
  description: null,
  category: null,
  specifications: [],
  product_items: [],
  status: "",
  created_by_uuid: "",
};

export const defaultMaterialRequestState: IMaterialRequestState = {
  material_request_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_material_request: {
    data: defaultMaterialRequest,
    loading: LoadState.NotLoaded,
    error: null,
  },
};

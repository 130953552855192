import { LoadState } from "../../constants/enums";
import {
  IDrawingUpload,
  IDrawingUploadState,
  IOcrJob,
  IUpdateDrawingInfo,
} from "./drawing-uploads.types";

export const defaultDrawingUpload: IDrawingUpload = {
  Description: ["", 0],
  DRGno: ["", 0],
  drawingFile: "",
  sod_no: "",
  ocr_log_uuid: "",
};

export const defaultDrawingUploadState: IDrawingUploadState = {
  drawing_upload_list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_drawing_upload: {
    data: [],
    loading: LoadState.NotLoaded,
    error: null,
  },
};

export const defaultUpdateDrawingInfo: IUpdateDrawingInfo = {
  sod_no: "",
  drawing_items: [],
};

export const defaultOcrJob: IOcrJob = {
  ocr_log_uuid: "",
  drawing_no: "",
  accuracy_drawing_no: 0,
  description: "",
  accuracy_description: 0,
  product_code: "",
  accuracy_product_code: 0,
  s3_link: null,
  transaction_uuid: "",
  sod_no: null,
  create_date: "",
  create_time: "",
  status: "ACTIVE",
};

import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  APP_MRP_ALLOCATIONS_APPROVALS_ROUTE,
  APP_MRP_ALLOCATIONS_ROUTE,
} from "./MrpAllocations.constants";

const MrpAllocationsTable = Loadable(
  lazy(() =>
    import("./MrpAllocationList").then(({ MrpAllocationList }) => ({
      default: MrpAllocationList,
    })),
  ),
);

const ManageMrpAllocation = Loadable(
  lazy(() =>
    import("./ManageMrpAllocation").then(({ ManageMrpAllocation }) => ({
      default: ManageMrpAllocation,
    })),
  ),
);

const MrpAllocationsApprovalTable = Loadable(
  lazy(() =>
    import("./approvals/MrpAllocationsApprovalTable").then(
      ({ MrpAllocationsApprovalTable }) => ({
        default: MrpAllocationsApprovalTable,
      }),
    ),
  ),
);

const ViewOrderMrpAllocations = Loadable(
  lazy(() =>
    import("./ViewOrderMrpAllocations").then(({ ViewOrderMrpAllocations }) => ({
      default: ViewOrderMrpAllocations,
    })),
  ),
);

export const mrpAllocationsRoutes = [
  {
    path: APP_MRP_ALLOCATIONS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.MRP_ALLOCATION]}>
        <MrpAllocationsTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_MRP_ALLOCATIONS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.MRP_ALLOCATION]}>
        <ManageMrpAllocation />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_MRP_ALLOCATIONS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.MRP_ALLOCATION]}>
        <ManageMrpAllocation />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_MRP_ALLOCATIONS_APPROVALS_ROUTE}`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_MRP_ALLOCATION]}>
        <MrpAllocationsApprovalTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_MRP_ALLOCATIONS_ROUTE}/order/:order_no`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.APPROVALS_MRP_ALLOCATION]}>
        <ViewOrderMrpAllocations />
      </AuthorizedRoute>
    ),
  },
];

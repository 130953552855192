import { ICustomerState } from "./customer/customer.types";
import { ICustomizerState } from "./customizer/customizerReducer";
import { IMessagesState } from "./messages/messages.types";
import { initialMessagesState } from "./messages/messagesState";
// import { IAutomobilePolicyRiskLocationState, defaultAutomobilePolicyRiskLocationState } from "./automobilePolicyRiskLocation/automobilePolicyRiskLocation.types";
import { defaultCommonState, ICommonState } from "./common/common.types";

import { IUsersState } from "./UserProfileList/userProfile.types";
import { initialUserProfileState } from "./UserProfileList/userprofileState";

import { IDataManagementState } from "./DataManagement/dataManagement.types";
import { defaultDataManagementState } from "./DataManagement/defaultState";
import {
  IApprovalState,
  intitialApprovalState,
} from "./approval/approvals/approvals.types";
import { IApprovalItemState } from "./approval/productExpiry";
import { initialApprovalItemState } from "./approval/productExpiry/defaultState";
import { IApprovalQuoteState } from "./approval/quote";
import { defaultApprovalQuoteState } from "./approval/quote/defaultState";
import { initialBomState } from "./bom";
import { IBomState } from "./bom/bom.types";
import { ICategoryState } from "./cateogory/category.types";
import { defaultCategoryState } from "./cateogory/defaultState";
import { defaultCommentState, ICommentState } from "./comments";
import { ICompanyState, initialCompanyState } from "./company/company.types";
import { IContactsState, initialContactState } from "./contacts/contacts.types";
import { ICostingSheetState } from "./costingSheet";
import { defaultCostingSheetState } from "./costingSheet/defaultState";
import { defaultCustomerState } from "./customer";
import { IDeviationState, initialDeviationState } from "./deviation";
import { IDrawingState, initialDrawingState } from "./drawing";
import {
  defaultDrawingUploadState,
  IDrawingUploadState,
} from "./drawing-uploads";
import { defaultDrawingItemState, IDrawingItemState } from "./drawingItem";
import { defaultEcnState, IEcnState } from "./ecn";
import { defaultEnquiryState } from "./enquiries/enquiry.state";
import { IEnquiryState } from "./enquiries/enquiry.types";
import { defaultMasterBomState } from "./masterBom";
import { IMasterBomState } from "./masterBom/masterBom.types";
import {
  defaultMRPAllocationState,
  IMrpAllocationsState,
} from "./mrp-allocations";
import { defaultNcpState, INcpState } from "./ncp";
import {
  inititalOpportunityState,
  IOpportunityState,
} from "./opportunites/opportunites.types";
import { defaultPartItemState, IPartItemState } from "./partItem";
import { defaultProductsState, IProductsState } from "./products";
import {
  defaultPurchaseOrderState,
  IPurchaseOrderState,
} from "./purchase-order";
import {
  defaultPurchaseRequisitionState,
  IPurchaseRequisitionState,
} from "./purchase-requisition";
import { defaultQuoteState } from "./quote/defaultState";
import { IQuoteState } from "./quote/quote.types";
import { defaultRenderingState } from "./rendering/defaultState";
import { IRenderingState } from "./rendering/rendering.types";
import { initialRoutingState } from "./routing";
import { IRoutingState } from "./routing/routing.types";
import {
  defaultOrderProcessingState,
  IOrderProcessingState,
} from "./sales/salesOrder";
import {
  initialSecurityState,
  ISecurityState,
} from "./security/security.types";
import {
  initialTaskBoardState,
  ITaskBoardState,
} from "./taskBoard/taskBoard.types";
import { defaultVendorState, IVendorState } from "./vendors";
import { defaultWarehouseState, IWarehouseState } from "./warehouse";
import { defaultWorkOrderState, IWorkOrderState } from "./workOrder";
import { defaultWebProductState, IWebProductState } from "./webProduct";
import {
  IExpressionTransformationState,
  initialExpressionTransformation,
} from "./expressionTransformation/expressionTransformation.types";
import { IRequestState } from "./request/request.types";
import { defaultRequestState } from "./request/defaultState";
import { defaultBdlState, IBdlState } from "./bdl";
import {
  defaultInternalOrderState,
  IInternalOrderState,
} from "./internal-orders";
import {
  defaultMaterialRequestState,
  IMaterialRequestState,
} from "./material-request";

export interface IDropdownListType {
  key: string;
  text: string;
  value: string;
}

export interface IStoreState {
  customizerReducer: ICustomizerState;
  customer: ICustomerState;
  message: IMessagesState;

  userprofile: IUsersState;
  common: ICommonState;

  opportunites: IOpportunityState;
  enquiries: IEnquiryState;
  company: ICompanyState;
  contacts: IContactsState;
  products: IProductsState;
  category: ICategoryState;
  bom: IBomState;
  routing: IRoutingState;
  masterBom: IMasterBomState;
  drawing: IDrawingState;
  deviation: IDeviationState;
  ecn: IEcnState;
  ncp: INcpState;
  partItem: IPartItemState;
  drawingItem: IDrawingItemState;
  quotes: IQuoteState;
  costingSheet: ICostingSheetState;
  taskBoard: ITaskBoardState;
  security: ISecurityState;
  rendering: IRenderingState;
  dataManagement: IDataManagementState;
  approvalQuotes: IApprovalQuoteState;
  approvalItem: IApprovalItemState;
  comment: ICommentState;
  orderProcessing: IOrderProcessingState;
  approvals: IApprovalState;
  workOrder: IWorkOrderState;
  drawingUploads: IDrawingUploadState;
  mrp_allocations: IMrpAllocationsState;
  vendors: IVendorState;
  purchaseRequisition: IPurchaseRequisitionState;
  purchaseOrder: IPurchaseOrderState;
  warehouse: IWarehouseState;
  webProduct: IWebProductState;
  expressionTransformation: IExpressionTransformationState;
  request: IRequestState;
  bdl: IBdlState;
  internalOrders: IInternalOrderState;
  material_request: IMaterialRequestState;
}

export const initialStoreState: IStoreState = {
  customizerReducer: {
    activeDir: "ltr",
    activeNavbarBg: "#0b70fb", // This can be any color,
    activeSidebarBg: "#ffffff", // This can be any color
    activeMode: "light", // This can be light or dark
    activeTheme: "ROCKWORTH_THEME", // BLUE_THEME, GREEN_THEME, RED_THEME, BLACK_THEME, PURPLE_THEME, INDIGO_THEME
    SidebarWidth: 240,
    sidebarOpen: true,
  },

  customer: defaultCustomerState,
  message: initialMessagesState,

  common: defaultCommonState,

  userprofile: initialUserProfileState,

  opportunites: inititalOpportunityState,
  enquiries: defaultEnquiryState,
  company: initialCompanyState,
  contacts: initialContactState,
  products: defaultProductsState,
  category: defaultCategoryState,
  bom: initialBomState,
  routing: initialRoutingState,
  masterBom: defaultMasterBomState,
  drawing: initialDrawingState,
  deviation: initialDeviationState,
  ecn: defaultEcnState,
  ncp: defaultNcpState,
  partItem: defaultPartItemState,
  drawingItem: defaultDrawingItemState,
  quotes: defaultQuoteState,
  costingSheet: defaultCostingSheetState,
  taskBoard: initialTaskBoardState,
  security: initialSecurityState,
  rendering: defaultRenderingState,
  dataManagement: defaultDataManagementState,
  approvalQuotes: defaultApprovalQuoteState,
  approvalItem: initialApprovalItemState,
  comment: defaultCommentState,
  orderProcessing: defaultOrderProcessingState,
  approvals: intitialApprovalState,
  workOrder: defaultWorkOrderState,
  drawingUploads: defaultDrawingUploadState,
  mrp_allocations: defaultMRPAllocationState,
  vendors: defaultVendorState,
  purchaseRequisition: defaultPurchaseRequisitionState,
  purchaseOrder: defaultPurchaseOrderState,
  warehouse: defaultWarehouseState,
  webProduct: defaultWebProductState,
  expressionTransformation: initialExpressionTransformation,
  request: defaultRequestState,
  bdl: defaultBdlState,
  internalOrders: defaultInternalOrderState,
  material_request: defaultMaterialRequestState,
};

import produce from "immer";

import {
  CLEAR_MATERIAL_REQUEST,
  CLEAR_MATERIAL_REQUEST_STATE,
  FETCH_MATERIAL_REQUEST_FAILED,
  FETCH_MATERIAL_REQUEST_LIST_FAILED,
  FETCH_MATERIAL_REQUEST_LIST_PROGRESS,
  FETCH_MATERIAL_REQUEST_LIST_SUCCESS,
  FETCH_MATERIAL_REQUEST_PROGRESS,
  FETCH_MATERIAL_REQUEST_SUCCESS,
} from "./material-request.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultMaterialRequest,
  defaultMaterialRequestState,
  IMaterialRequestActions,
} from ".";

export const materialRequestReducer = (
  state: IStoreState["material_request"] = defaultMaterialRequestState,
  action: IMaterialRequestActions,
) => {
  switch (action.type) {
    case FETCH_MATERIAL_REQUEST_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.material_request_list.loading = LoadState.InProgress;
        draftState.material_request_list.data = [];
        draftState.material_request_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_MATERIAL_REQUEST_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.material_request_list.loading = LoadState.Loaded;
        draftState.material_request_list.data = data;
        draftState.material_request_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_MATERIAL_REQUEST_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.material_request_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_MATERIAL_REQUEST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_material_request.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MATERIAL_REQUEST_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_material_request.loading = LoadState.Loaded;
        draftState.single_material_request.data = data;
      });
      return newState;
    }
    case FETCH_MATERIAL_REQUEST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_material_request.loading = LoadState.Failed;
        draftState.single_material_request.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_MATERIAL_REQUEST: {
      const newState = produce(state, (draftState) => {
        draftState.single_material_request.loading = LoadState.NotLoaded;
        draftState.single_material_request.data = defaultMaterialRequest;
        draftState.single_material_request.error = null;
      });
      return newState;
    }

    case CLEAR_MATERIAL_REQUEST_STATE: {
      return defaultMaterialRequestState;
    }

    default: {
      return state;
    }
  }
};

import produce from "immer";

import {
  CLEAR_MRP_ALLOCATION,
  CLEAR_MRP_ALLOCATION_STATE,
  FETCH_MRP_ALLOCATION_FAILED,
  FETCH_MRP_ALLOCATION_LIST_FAILED,
  FETCH_MRP_ALLOCATION_LIST_PROGRESS,
  FETCH_MRP_ALLOCATION_LIST_SUCCESS,
  FETCH_MRP_ALLOCATION_PROGRESS,
  FETCH_MRP_ALLOCATION_SUCCESS,
  FETCH_MRP_PART_CODE_DESCRIPTION_FAILED,
  FETCH_MRP_PART_CODE_DESCRIPTION_PROGRESS,
  FETCH_MRP_PART_CODE_DESCRIPTION_SUCCESS,
  FETCH_ORDER_NO_MRP_ALLOCATIONS_FAILED,
  FETCH_ORDER_NO_MRP_ALLOCATIONS_PROGRESS,
  FETCH_ORDER_NO_MRP_ALLOCATIONS_SUCCESS,
} from "./mrp-allocations.actions";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  defaultMRPAllocation,
  defaultMRPAllocationState,
  MrpAllocationActions,
} from ".";

export const mrpAllocationsReducer = (
  state: IStoreState["mrp_allocations"] = defaultMRPAllocationState,
  action: MrpAllocationActions,
) => {
  switch (action.type) {
    case FETCH_MRP_ALLOCATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.mrp_allocations_list.loading = LoadState.InProgress;
        draftState.mrp_allocations_list.data = [];
        draftState.mrp_allocations_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_MRP_ALLOCATION_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.mrp_allocations_list.loading = LoadState.Loaded;
        draftState.mrp_allocations_list.data = data;
        draftState.mrp_allocations_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_MRP_ALLOCATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.mrp_allocations_list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_MRP_ALLOCATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_mrp_allocation.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MRP_ALLOCATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_mrp_allocation.loading = LoadState.Loaded;
        draftState.single_mrp_allocation.data = data;
      });
      return newState;
    }
    case FETCH_MRP_ALLOCATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_mrp_allocation.loading = LoadState.Failed;
        draftState.single_mrp_allocation.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_MRP_ALLOCATION: {
      const newState = produce(state, (draftState) => {
        draftState.single_mrp_allocation.loading = LoadState.NotLoaded;
        draftState.single_mrp_allocation.data = defaultMRPAllocation;
        draftState.single_mrp_allocation.error = null;
      });
      return newState;
    }

    case CLEAR_MRP_ALLOCATION_STATE: {
      return defaultMRPAllocationState;
    }

    case FETCH_ORDER_NO_MRP_ALLOCATIONS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_order_mrp_allocations.loading = LoadState.InProgress;
        draftState.single_order_mrp_allocations.data = [];
        draftState.single_order_mrp_allocations.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_ORDER_NO_MRP_ALLOCATIONS_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_order_mrp_allocations.loading = LoadState.Loaded;
        draftState.single_order_mrp_allocations.data = data;
        draftState.single_order_mrp_allocations.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_ORDER_NO_MRP_ALLOCATIONS_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.single_order_mrp_allocations.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_MRP_PART_CODE_DESCRIPTION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.mrp_part_code_description.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_MRP_PART_CODE_DESCRIPTION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.mrp_part_code_description.loading = LoadState.Loaded;
        draftState.mrp_part_code_description.data = data;
      });
      return newState;
    }
    case FETCH_MRP_PART_CODE_DESCRIPTION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.mrp_part_code_description.loading = LoadState.Failed;
        draftState.mrp_part_code_description.error = errorMessage;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};

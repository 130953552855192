import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_MATERIAL_REQUEST_ROUTE } from "./MaterialRequests.constanrts";

const MaterialRequestsTable = Loadable(
  lazy(() =>
    import("./MaterialRequestsTable").then(({ MaterialRequestsTable }) => ({
      default: MaterialRequestsTable,
    })),
  ),
);

const ManageMaterialRequest = Loadable(
  lazy(() =>
    import("./ManageMaterialRequest").then(({ ManageMaterialRequest }) => ({
      default: ManageMaterialRequest,
    })),
  ),
);

export const materialRequestsRoutes = [
  {
    path: APP_MATERIAL_REQUEST_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.MRP_ALLOCATION]}>
        <MaterialRequestsTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_MATERIAL_REQUEST_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.MRP_ALLOCATION]}>
        <ManageMaterialRequest />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_MATERIAL_REQUEST_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.MRP_ALLOCATION]}>
        <ManageMaterialRequest />
      </AuthorizedRoute>
    ),
  },
];
